import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Back squat 4-4-4-4\\@85% 1RM`}</p>
    <p>{`Glute Ham Raise 4-4-4-4 (weighted if possible)`}</p>
    <p>{`then,`}</p>
    <p>{`5 Rounds for time of:`}</p>
    <p>{`10-Back squats (185/125)`}</p>
    <p>{`20-KBS (53/35)`}</p>
    <p>{`40-Double Unders`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`The next Strength Program will start Sunday, May 20th.  For more
info email Eric at Fallscitystrength\\@gmail.com.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      